import React from "react";
import logo from "./logo.png"
import "./Footer.scss"


export const Footer:React.FC<{}> = () => {
    return (
      <footer>
        <img src={logo} alt="" />
      </footer>
)
}
